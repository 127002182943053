@import "../../global.scss";

.nav{
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #fafafa;
}

.nav__topbar{
  width: 100%;
  height: 72px;

  padding: 0px 10px 0px 20px;

  transition: all .3s;
}

.nav__side{
  position: relative;
  height: calc(100vh - 72px);

  &-collapsed{
    width: 72px;
    padding: 80px 4px 10px 10px;
  }

  &-expanded{
    width: 200px;
    padding: 80px 4px 10px 10px;
  }

  &-conatiner{
    border-radius: 16px;

    overflow: hidden;
  }

  &-item{
    gap: 10px;
    padding: 15px;
    cursor: pointer;
  }

  &-item:hover{
    // background-color: #d3e7ef;
    background-color: #f1f1f1;
  }

  &-selected{
    gap: 10px;
    padding: 15px;
    background-color: $primary;
    // color: $primary-light;
  }

  &-expand{
    position: absolute;
    right: -30px;
    top: 50%;
    z-index: 100;
  }
}

.nav__top{
  min-height: 64px;
  width: 100%;
  padding: 5px 5px 5px 0px;
}

.nav__bottom{
  width: 100%;
  height: 72px;
  min-height: 72px;
  border-top: 1px solid $primary-light;
  transition: all .3s; 

  &-item{
    // gap: 10px;
    padding: 5px 15px;
    cursor: pointer;
    border-radius: 12px;
  }

  &-item:hover{
    background-color: #d3e7ef;
  }

  &-selected{
    // gap: 5px;
    padding: 5px 15px;
    border-radius: 12px;
    background-color: $primary-dark;
    // color: $primary-light;
  }
}