$white: #ffffff;
$black: #000000;

// main colors
$primary-light:#e2f2f9;
$primary: #b8dff0;
$primary-dark: #8ecce7;

$secondary-light: #253f83;
$secondary: #1a2c5b;
$secondary-dark: #0f1933;

// flags
$success: #2e7d32;
$error: #d32f2f;
$warning: #ed6c02;

// text
$black-text: #000000;
$main-text: #0f1933;
$sub-text: #616161;