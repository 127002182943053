@import "../../global.scss";

.auth{
  height: 100vh;
  padding-top: 80px;
}

.auth__content{
  width: 100%;
  height: 60vh;

  // background-color: $primary-light;
  border-radius: 16px;
  // border: 1px solid $secondary; 
}

.auth__text{
  overflow: hidden;
  height: 0px; 
  // color: $primary-dark!important;
  text-align: center;
}

.auth__otp{
  width: 100%;
  margin: 20px 0px;
  display: flex;
  justify-content: space-between;

  &-input{
    height: 52px;
    min-width: 52px;
    border-radius: 12px;
    border: 1px solid;
    font-size: 22px;
    font-family: 'Urbanist', sans-serif;
    // background-color: $primary-light;
  }
}
