@import "../../global.scss";

.topbar{
  margin-bottom: 15px;
}


.app__topbar{
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  width: 100%;
  padding: 15px 0px;

  background-color: $primary-light;
  // border-bottom: 1px solid $secondary; 
}
