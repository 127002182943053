@import "../../global.scss";

.circle{
  
}

.user{
  border: 1px solid $secondary;
  border-radius: 16px;
  padding: 15px;
}

.circle__otp{
  width: 100%;
  margin: 20px 0px;
  display: flex;
  justify-content: space-between;

  &-input{
    height: 52px;
    min-width: 52px;
    border-radius: 12px;
    border: 1px solid;
    font-size: 22px;
    font-family: 'Urbanist', sans-serif;
    background-color: $white;
  }
}


.wallet__creditcard{
  height: 120px;
  width: 100%;
  padding: 15px;
  border-radius: 16px;
  background-color: #f1f1f1;
  transition: all .2s;
  border: 3px solid #f1f1f1;

  &-selected{
    border: 3px solid $secondary;
    background-color: $primary-light;
  }

  &-selected:hover{
    border: 3px solid $secondary;
  }
}

.wallet__creditcard:hover{
  background-color: $primary-light;
  cursor: pointer;
  scale: 1.02;
}

.wallet__user{
  height: 120px;
  width: 100%;
  padding: 15px;
  border-radius: 16px;
  background-color: #f1f1f1;
  transition: all .2s;
  border: 3px solid #f1f1f1;

  &-selected{
    border: 3px solid $secondary;
    background-color: $primary-light;
  }

  &-selected:hover{
    border: 3px solid $secondary;
  }
}

.wallet__user:hover{
  background-color: $primary-light;
  cursor: pointer;
  scale: 1.02;
}