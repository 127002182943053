* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body{
  // font-family: 'Urbanist', sans-serif;
  font-family: "Plus Jakarta Sans", sans-serif;
}

html { scroll-behavior: smooth; } 

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}