@import "../../global.scss";

.vcn{
  
}

.vcn__creditcard{
  height: 120px;
  width: 100%;
  padding: 15px;
  border-radius: 16px;
  background-color: #f1f1f1;
  transition: all .2s;
  border: 3px solid #f1f1f1;

  &-selected{
    border: 3px solid $secondary;
    background-color: $primary-light;
  }

  &-selected:hover{
    border: 3px solid $secondary;
  }
}

.vcn__creditcard:hover{
  background-color: $primary-light;
  cursor: pointer;
  scale: 1.02;
}

.vcn__otp{
  width: 100%;
  margin: 20px 0px;
  padding: 20px 60px;
  display: flex;
  justify-content: space-evenly;

  &-input{
    height: 52px;
    min-width: 52px;
    border-radius: 10px;
    border: 1px solid;
    font-size: 22px;
    font-family: 'Urbanist', sans-serif;
    background-color: $white;
  }
}

.vcn__card{
  max-width: 360px;
  width: 100%;
  height: 200px;
  border-radius: 16px;
  padding: 15px;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.vcn__card-cancelled{
  background-color: #faf1f1;
}